import { useState, useMemo } from "react";
import styles from "./App.module.css";

import PeopleCountRadio from "./components/PeopleCountRadio";
import InputWithSlider from "./components/InputWithSlider";

const sandnesBankColors = {
  "--color-font-secondary": "#555",
  "--color-input-font": "#222",
  "--color-input-background": "#ffd600",
  "--color-slider-background": "#ffd600",
  "--color-slider-thumb": "#222",
  "--color-radio-background": "#ffd600",
  "--color-radio-dot": "#222",
};

const dividendFactorJaeren = 0.0028;
const dividendFactorSandnes = 0.0026;
const minimumPaymentJaeren = 100;
const minimumPaymentSandnes = 0;

function App() {
  const [loan, setLoan] = useState(2000000);
  const [deposit, setDeposit] = useState(1000000);
  const [peopleCount, setPeopleCount] = useState("1");

  const loanMaxAmount = +peopleCount === 2 ? 4000000 : 2000000;
  const depositMaxAmount = 2000000;

  const windowUrl = window.location.search;
  const params = useMemo(() => new URLSearchParams(windowUrl), [windowUrl]);
  const bank = params.get("bank"); // Should be either "sandnes" or "jaeren"

  function onPeopleCountChange(value) {
    if (value === "1" && formatToInt(loan) > 2000000) {
      setLoan(formatToCurrency(2000000));
    }
    setPeopleCount(value);
  }

  function handleLoanChange(input) {
    if (!input) return setLoan("0");

    const amount = formatToInt(input);
    if (isNaN(amount)) return;

    setLoan(
      amount < 0
        ? "0"
        : amount > loanMaxAmount
        ? formatToCurrency(loanMaxAmount)
        : formatToCurrency(amount)
    );
  }

  function handleDepositChange(input) {
    if (!input) return setDeposit("0");

    const amount = formatToInt(input);
    if (isNaN(amount)) return;

    setDeposit(
      amount < 0
        ? "0"
        : amount > depositMaxAmount
        ? formatToCurrency(depositMaxAmount)
        : formatToCurrency(amount)
    );
  }

  // Convert number to currency string
  function formatToCurrency(value) {
    return Number(value)
      ?.toLocaleString("nb-NO", {
        style: "currency",
        currency: "NOK",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      })
      .replace("kr", "")
      .trim();
  }

  // Convert currency formatted string to integer
  function formatToInt(input) {
    return typeof input === "number"
      ? input
      : parseInt(input.replace(/[^0-9.-]+/g, ""));
  }

  function getDividend() {
    const factor =
      bank === "sandnes" ? dividendFactorSandnes : dividendFactorJaeren;
    const minimumPayment =
      bank === "sandnes" ? minimumPaymentSandnes : minimumPaymentJaeren;

    const dividend = (formatToInt(loan) + formatToInt(deposit)) * factor;
    const result = dividend < minimumPayment ? 0 : dividend;

    return result;
  }

  return (
    <div
      className={styles.container}
      style={bank === "sandnes" ? sandnesBankColors : null}
    >
      <section className={styles.card}>
        <h2 className={styles.heading}>
          Regn ut hva du får i kundeutbytte
        </h2>
        <p className={styles.description}>
          Kalkulatoren viser sånn cirka hva du får i utbytte, med utgangspunkt i
          gjennomsnittlig lån og innskudd per dag i 2023.
        </p>

        <PeopleCountRadio
          peopleCount={peopleCount}
          onChange={onPeopleCountChange}
        />

        <InputWithSlider
          label={"Lån"}
          subLabel={`inntil ${+peopleCount === 2 ? 4 : 2} mill`}
          max={loanMaxAmount}
          value={formatToInt(loan)}
          onChange={handleLoanChange}
          formatToCurrency={formatToCurrency}
        />

        <InputWithSlider
          label={"Innskudd"}
          subLabel={"inntil 2 mill"}
          max={depositMaxAmount}
          value={formatToInt(deposit)}
          onChange={handleDepositChange}
          formatToCurrency={formatToCurrency}
        />

        <section className={styles.result}>
          <h3 className={styles.header}>Beregnet kundeutbytte blir</h3>
          <h3 className={styles.finalAmount}>
            {formatToCurrency(getDividend())} kr
            <sup className={styles.star}>*</sup>
          </h3>

          <p className={styles.termsNote}>
            * Det oppgitte tallet{" "}
            <strong className={styles.bold}>indikerer</strong> hva du får
            utbetalt. Det kan bli litt mer - og det kan bli litt mindre.{" "}
            {bank === "sandnes"
              ? `Det oppgitte taller indikerer hva du får utbetalt dersom bankens
            forstanderskap vedtar styrets forslag om kundeutbytte for året 2023. Det
            blir avgjort 10. april 2024. Kalkulatoren beregner ut fra at du har vært
            kunde hele året. Har du ikke vært kunde i hele 2023, får du likevel
            kundeutbytte, men kalkulatoren vil da ikke gi deg riktig beløp.`
              : `Utbyttesummen refererer til foreløpig overskuddsdisponering. Formelt
            vedtak fattes av forstanderskapet 4. april. Kalkulatoren beregner ut
            fra at du har vært kunde hele året. Nedre utbetalingsgrense er 100
            kroner. Dersom du har hatt mindre enn 40 000 kroner i lån og/eller
            innskudd, vil du i 2023 ikke kvalifisere til minimumsutbetalingen på
            100 kroner.`}
          </p>
        </section>
      </section>
    </div>
  );
}

export default App;
